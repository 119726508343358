.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  /* margin-top: 44px; */
  min-width: 60px;
  max-width: 80px;
  background: #000000;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
  color: #a2a2a2;
  transition: all 200ms ease-in;
  height: 100%;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
}

.sideBarList1 {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
}

#sidebar.active ul li a span {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms ease-in;
}

#sidebar ul li a span {
  opacity: 0;
  width: 200px;
  transform: translateX(-100%);
  transition: none;
  transition: none 0s ease 0s !important;
  position: absolute;
}

i.fa.fa-angle-left.innerIcoTop {
  padding-left: 23px;
  font-size: 21px;
  /* margin-top: 407px; */
  color: #565454;
  font-weight: bold;
}

i.fa.fa-angle-right.innerIcoTop2 {
  padding-left: 23px;
  font-size: 21px;
  /* margin-top: 407px; */
  color: #565454;
  font-weight: bold;
}

#sidebar.active {
  min-width: 200px;
  max-width: 200px;
  z-index: 500;
  position: fixed;
  height: 100%;
}

li.nav-item {
  padding-right: 22px;
}

.arrowBottom {
  position: absolute;
  bottom: 10px;
}

.hidetext {
  display: none;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul li a {
  padding: 10px 0;
  font-size: 1.1rem;
  display: block;
  color: #4c4c4c;
  text-align: center;
}

#sidebar.active ul li a {
  text-align: left !important;
}

#sidebar ul li a:hover {
  background: #e0e0e0 !important;
  border-right: 2px solid #28a745 !important;
}

.sidebar-header {
  margin-top: 65px;
}

#sidebar ul li a i {
  font-size: 20px;
  margin: 0 16px;
}

#sidebar ul li.active > a,
a[aria-expanded='false'] {
  color: #2d69eb !important;
  background: #e0e9fc !important;
  border-right: 2px solid #2d69eb !important;
}

.icon-styling {
  margin-right: 12px;
  margin-left: 12px;
  font-size: 19px !important;
  opacity: 0.8;
  color: #ffffff;
}

.icon-styling:hover {
  color: #f7eeee !important;
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.img-styling {
  height: 17px;
}

.img2-styling {
  height: 17px;
}

/* ------------------------side bar end -----------------*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiToggleButton-root {
  height: 32px !important;
}

.css-4ljt47-MenuList {
  max-height: 120px !important;
}

.css-11unzgr {
  max-height: 120px !important;
}

.toggle-container {
  position: absolute;
  right: 0;
  top: -38px;
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2) !important;
}

.MuiCardHeader-root {
  padding: 8px 16px !important;
}

.css-1hwfws3 {
  flex-wrap: nowrap !important;
}

.overlay-image {
  width: 150px !important;
  margin: 0 auto !important;
}

.enlarged-image {
  width: 400px !important;
  height: 550px !important;
}

.grid-main .sc-AxhUy .sc-AxgMl:nth-child(5n+5) .enlarged-image{
  position: absolute !important;
  left: -280% !important;
}

.grid-main .sc-AxhUy .sc-AxgMl:nth-last-child(-n+5) .enlarged-image{
  position: absolute !important;
  top: -120% !important;
}

.react-dropdown-select {
  border: 1px solid grey !important;
}

.css-9gakcf-option {
  background-color: #FFF !important;
  color: #000 !important
}