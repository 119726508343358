.toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #ffffff !important;
  border: 1px solid #34a853 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34a853;
}

.toast-error-container {
  color: rgb(255, 17, 17) !important;
  border-radius: 8px !important;
  background: #bb0000 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-warning-container {
  color: #ffcc00 !important;
  border-radius: 8px !important;
  background: #ffcc00 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-info-container {
  color: rgb(15, 166, 213) !important;
  border-radius: 8px !important;
  background: rgb(15, 166, 213) !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}
